<template>
  <div class="topBox">
    <div class="logo" @click="$router.push('/')">
      <img src="../../assets/logo.png" alt="" />
      <span>台湾文献资源库</span>
    </div>
    <div class="search">
      <div class="search-content wrap" v-if="!isSenior">
        <el-select
          class="select"
          v-model="searchForm.componentsName"
          placeholder="请选择"
          style="margin-right: 15px"
          ref="addTreeBySearch"
        >
          <el-option :value="selectTree">
            <el-tree
              :data="treeList"
              :props="defaultProps"
              @node-click="handleNodeClickBySearch"
              default-expand-all
              ref="dataTree"
            ></el-tree>
          </el-option>
        </el-select>

        <el-input class="ipt" v-model="keyword" placeholder="请输入查询内容">
          <template #suffix>
            <el-button
              class="btn"
              type="info"
              plain
              icon="el-icon-search"
              @click="handleSearch"
              >搜索</el-button
            >
          </template>
        </el-input>
        <span @click="onChange">高级检索</span>
      </div>
      <div class="search2 wrap" v-else>
        <div class="selectBox">
          <!-- <el-select
            class="select"
            v-model="select"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option label="全部" value="all"></el-option>
            <el-option label="方志" value="131"></el-option>
            <el-option label="地名辞书" value="132"></el-option>
            <el-option label="家谱" value="133"></el-option>
            <el-option label="契约" value="135"></el-option>
            <el-option label="报纸" value="120"></el-option>
          </el-select> -->
          <el-select
            class="select"
            v-model="searchForm.componentsName"
            placeholder="请选择"
            style="margin-right: 15px"
            ref="addTreeBySearch"
          >
            <el-option :value="selectTree">
              <el-tree
                :data="treeList"
                :props="defaultProps"
                @node-click="handleNodeClickBySearch"
                default-expand-all
                ref="dataTree"
              ></el-tree>
            </el-option>
          </el-select>
          <span></span>
        </div>
        <div class="inputBox">
          <div class="item">
            <span>题名：</span>
            <el-input v-model="title" placeholder="请输入题名"></el-input>
          </div>
          <div class="item">
            <span>作者：</span>
            <el-input
              v-model="authorPerson"
              placeholder="请输入作者"
            ></el-input>
          </div>
          <div class="item">
            <span>时间：</span>
            <el-input v-model="createYear" placeholder="请输入时间"></el-input>
          </div>
          <div class="item">
            <span>关键字：</span>
            <el-input v-model="keyword" placeholder="请输入关键字"></el-input>
          </div>
          <div class="item" v-show="select === '131'">
            <span>地区：</span>
            <el-input v-model="area" placeholder="请输入地区"></el-input>
          </div>
          <div class="item">
            <span>&nbsp;&nbsp;</span>
            <div class="operate">
              <el-button @click="handleSearch">查询</el-button>
              <span @click="onChange">全文检索</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onSearch } from "../../api/literature.js";
export default {
  data() {
    return {
      keyword: "",
      select: "131",
      pageIndex: 1,
      pageSize: 10,
      title: "",
      createYear: "",
      area: "",
      authorPerson: "",
      selectTree: [],
      searchForm: {
        componentsName: "方志",
      },
      version: "",
      treeList: [
        { label: "全部", value: "all" },
        {
          label: "方志",
          value: "131",
          children: [
            { label: "县市志", value: "旧志", p: "131" },
            { label: "省市志", value: "省市志", p: "131" },
            { label: "乡镇志", value: "乡镇志", p: "131" },
          ],
        },
        { label: "地名辞书", value: "132" },
        { label: "家谱", value: "133" },
        { label: "契约", value: "135" },
        { label: "报纸", value: "120" },
        { label: "期刊", value: "110" },
        { label: "人物", value: "2" },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  props: {
    "is-senior": {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    // 全文检索， 高级检索切换
    onChange() {
      this.$emit("update:is-senior", !this.isSenior);
      this.keyword = "";
      this.title = "";
      this.createYear = "";
      this.area = "";
      this.authorPerson = "";
      this.$store.commit("user/SET_SENIOR_DATA", {
        title: this.title,
        authorPerson: this.authorPerson,
        createYear: this.createYear,
        area: this.area,
        keyword: this.keyword,
        version: this.version,
      });
      this.pageIndex = 1;
    },
    async handleSearch() {
      if (this.$route.path !== "/searchResult") {
        if (!this.isSenior) {
          let routeData = this.$router.resolve({
            path: "/searchResult",
            query: {
              selectType: this.select,
              keyword: this.keyword,
              version: this.version,
              componentsName: this.searchForm.componentsName,
            },
          });
          window.open(routeData.href, "_blank");
        } else {
          this.$store.commit("user/SET_SENIOR_DATA", {
            title: this.title,
            authorPerson: this.authorPerson,
            createYear: this.createYear,
            area: this.area,
            keyword: this.keyword,
            version: this.version,
          });
          let routeData = this.$router.resolve({
            path: "/searchResult",
            query: {
              selectType: this.select,
              componentsName: this.searchForm.componentsName,
              isSenior: true,
            },
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        this.$parent.loading = true;
        this.$parent.seniorList = [];
        try {
          let data = {};
          if (this.isSenior) {
            data = {
              indexType: this.select,
              keyword: this.keyword,
              authorPerson: this.authorPerson,
              createYear: this.createYear,
              area: this.area,
              title: this.title,
              version: this.version,
            };
          } else {
            data = {
              indexType: this.select,
              keyword: this.keyword,
              version: this.version,
            };
          }
          const res = await onSearch({
            ...data,
            indexType: this.select === "all" ? "" : this.select,
            PageIndex: this.pageIndex,
            PageSize: this.pageSize,
          });

          this.$emit("setData", res, data);
        } catch (error) {
          console.log(error);
        } finally {
          this.$parent.loading = false;
        }
      }
    },
    //搜索框点击树
    handleNodeClickBySearch(data) {
      this.searchForm.componentsName = data.label;
      if (data.p) {
        this.select = data.p;
        this.version = data.value;
      } else {
        this.select = data.value;
        this.version = "";
      }
      if (data.value !== "131" || !data.p) {
        this.area = "";
      }
      this.$refs.addTreeBySearch.blur();
    },
  },
  created() {
    if (this.$route.query.selectType) {
      this.select = this.$route.query.selectType;
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.componentsName) {
      this.searchForm.componentsName = this.$route.query.componentsName;
    }
    if (this.$route.query.isSenior === "true") {
      let data = this.$store.state.user.seniorData;
      this.keyword = data.keyword;
      this.createYear = data.createYear;
      this.authorPerson = data.authorPerson;
      this.area = data.area;
      this.title = data.title;
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep.el-scrollbar__view,
.el-select-dropdown__list {
  .el-select-dropdown__item {
    height: auto;
    padding: 0;
  }
}
.topBox {
  position: relative;
  height: 300px;
  background: url("../../assets/bg.jpg");
  .logo {
    position: absolute;
    top: 20%;
    left: 5%;
    cursor: pointer;
    img {
      width: 64px;
      height: 64px;
      vertical-align: middle;
    }
    span {
      margin-left: 10px;
      font-size: 36px;
      color: #fff;
    }
  }
  .search {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: 10px;
    .search-content {
      display: flex;
      width: 1120px;
      align-items: center;
      justify-content: space-evenly;

      span {
        display: inline-block;
        display: inline-block;
        width: 100px;
        text-align: center;
        cursor: pointer;
        color: #fff;
      }
      ::v-deep .ipt {
        .btn {
          border: 0;
        }
        width: 820px;
        .el-input__inner {
          height: 50px;
        }
        .el-input__inner::placeholder {
          font-size: 16px;
        }
        .el-input__suffix {
          display: flex;
          align-items: center;
        }
        .el-button--info.is-plain:active,
        .is-plain:hover {
          background: #4c69bb;
        }
        .icon {
          display: flex;
          align-items: center;
          font-size: 20px;
        }
      }

      .select {
        ::v-deep .el-input__inner {
          height: 50px;
          width: 215px;
        }
      }
    }

    .search2 {
      display: flex;
      width: 1120px;
      height: 100px;
      align-items: center;
      justify-content: space-evenly;

      .selectBox {
        padding-top: 10px;
        height: 100%;
        box-sizing: border-box;
      }

      .inputBox {
        display: flex;
        flex-wrap: wrap;
        margin-left: 40px;
        .item {
          width: 250px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .operate {
            width: 178px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
          }
        }
      }
      span {
        color: #fff;
        display: inline-block;
        width: 100px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>