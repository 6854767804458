<template>
  <div class="container">
    <my-header :isSenior.sync="isSenior"/>
    <div class="box" v-if="type == 135">
      <div class="search">
        <el-input v-model="Title" placeholder="请输入中文题名">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="onSearch"
          ></el-button
        ></el-input>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :header-cell-style="{
          background: '#4f81bd',
          color: '#fff',
        }"
      >
        <el-table-column prop="title" label="中文题名" width="240px">
        </el-table-column>
        <el-table-column prop="alternative" label="主题" width="140px">
        </el-table-column>
        <el-table-column prop="keyword" label="关键词" width="140px">
        </el-table-column>
        <el-table-column prop="temporal" label="时间"> </el-table-column>
        <el-table-column prop="spatial" label="地理"> </el-table-column>
        <el-table-column prop="owner" label="原件典藏单位" width="140px">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table" v-else>
      <div class="title">地名辞书</div>
      <ul class="name">
        <li
          class="item"
          v-for="v in tableData"
          :key="v.id"
          @click="handleClick(v)"
        >
          {{ v.title }}
        </li>
      </ul>
    </div>
    <el-pagination
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import myHeader from "../../components/header/index.vue";
import { getHistorical } from "../../api/literature.js";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      type: "", // 135 契约  132 地名辞书
      Title: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type == 132) {
      this.pageSize = 50;
    }
    this.pageIndex = 1;
    this.getHistorical();
  },
  methods: {
    async getHistorical() {
      try {
        const res = await getHistorical({
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
          Title: this.Title,
          Type: this.type,
        });
        console.log(res, "res");
        this.tableData = res.items;
        this.total = res.totalCount;
      } catch (error) {
        console.log(error, "error");
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getHistorical();
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      this.getHistorical();
    },
    onSearch() {
      this.pageIndex = 1;
      this.getHistorical();
    },
    handleClick(data) {
      let routeData = this.$router.resolve({
        path: "/ebook",
        query: {
          sourceID: data.sourceID,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .box {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .search {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      span {
        display: inline-block;
        width: 100px;
      }
      .el-input {
        width: 300px;
      }
    }
  }
  .table {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .title {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #1a416c;
      background: #eee;
    }
    .name {
      display: flex;
      flex-wrap: wrap;
      background: #fff;

      .item {
        width: 250px;
        padding: 20px 5px;
        text-decoration: underline;
        color: #4c69bb;
        cursor: pointer;
        font-size: 16px;
      }

      .item:hover {
        color: red;
      }
    }
  }
  .el-pagination {
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>