import request from '@/utils/request'

// 获取市县
export const getCityOrDis = params => {
    return request({
        method: 'GET',
        url: '/external/area/city-and-district-list',
        params
    })
}

// 获取村镇
export const getTownOrVill = params => {
    return request({
        method: 'GET',
        url: '/external/area/town-ship-and-village-list',
        params
    })
}

// 获取市
export const getCity = params => {
    return request({
        method: 'GET',
        url: '/external/area/city-list',
        params
    })
}

// 获取县
export const getDis = params => {
    return request({
        method: 'GET',
        url: '/external/area/district-list',
        params
    })
}


// 已上架行政区划
export const getAreas = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/local-record/local-record-areas',
        params
    })
}

export const seniorSearch = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/local-record',
        params
    })
}


// 获取基础数据
export const getBaseData = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/local-record/items`,
        params
    })
}

// 契约 、 地名辞书
export const getHistorical = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/historical',
        params
    })
}

// 缩略图
export const getThumbnail = params => {
    return request({

        method: 'GET',
        url: '/knowledgeshow/ocr-record-appservice/document-ocr-dtos',
        params
    })
}

// 获取详情
export const getInfo = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/ocr-record-appservice/${params.id}/document-oCRInfo`,
        params: {
            keyWord: params.keyWord
        }
    })
}
// 获取家谱
export const getGenealogy = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/genealogy',
        params
    })
}

// 家谱详情
export const getGenInfo = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/genealogy/items',
        params
    })
}

// 搜索
export const onSearch = data => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/search/search-all',
        data,
        headers: {
            'Content-type': 'application/json'
        }
    })
}
export const getEBook = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/ebook',
        params
    })
}

// 获取封面列表
export const getCover = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/ebook',
        params
    })
}

export const getBookBaseData = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/local-record/items`,
        params
    })
}

export const getName = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/genealogy/items',
        params
    })
}


export const getPaper = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/paper',
        params
    })
}

// 原图局部段落
export const getRangeContent = data => {
    return request({
        method: 'POST',
        url: 'knowledgeshow/ocr-record-appservice/ebooks-content-range',
        data,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

// 电子书局部段落
export const getEbookRangeContent = data => {
    return request({
        method: "POST",
        url: "/knowledgeshow/ebook/ebooks-content-range",
        data,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

export const getEbookBaseData = id => {
    return request({
        method: "GET",
        url: `/knowledgeshow/document/${id}/base-data-item`,
    })
}

// 图片翻页
export const beforOrNext = params => {
    return request({
        method: "POST",
        url: `/knowledgeshow/ebook/${params.genealogyOCRInfoID}/befor-or-next`,
        params
    })
}

// 查看原图
export const viewPic = params => {
    return request({
        method: "POST",
        url: `/knowledgeshow/ebook/e-book-real-pic/${params.ebookId}`,
        params
    })
}

// 电子书搜索
export const searchContent = params => {
    return request({
        method: "POST",
        url: "/knowledgeshow/ebook/search-content",
        params
    })
}

// 电子书段落
export const getEbookNewContent = params => {
    return request({
        method: "POST",
        url: "/knowledgeshow/ebook/ebooks-content-new/" + params.ebookId,
        params
    })
}

// 获取本数
export const getEbook = params => {
    return request({
        method: "GET",
        url: "/knowledgeshow/ebook",
        params
    })
}